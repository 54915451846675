<script setup>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref, watch, onMounted } from "vue";
import axios from "axios";
import { useStore } from "vuex";

const store = useStore();

const formatDate = (date) => {
  return date.toISOString().split("T")[0];
};

const today = new Date();
const startDate = ref(today);
const endDate = ref(today);
const pickerDate = ref([startDate.value, endDate.value]);
const stakeSummary = ref([]);
const groupBy = ref("daily");

const formatPeriod = (timestamp) => {
  return timestamp.split('T')[0];
};

const fetchStakeSummary = async () => {
  const baseurl = process.env.VUE_APP_API_TAX_URL;
  const user = store.state.user;

  // Format start and end dates dynamically
  const start = formatDate(startDate.value);
  const end = formatDate(endDate.value);
  const group = groupBy.value;

  const url = `${baseurl}/stake/outcome?start=${start}&end=${end}&group_by=${group}`;

  try {
    const response = await axios.post(
        url,
        {},
        {
          headers: {
            "api-key": user.auth,
          },
        }
    );

    console.log("API Response:", response.data);

    if (response.data && response.data.stake_summary) {
      stakeSummary.value = response.data.stake_summary;
    } else {
      stakeSummary.value = [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Fetch data when the component mounts
onMounted(fetchStakeSummary);

// Watchers to update API call when date range or groupBy changes
watch(pickerDate, (newDateRange) => {
  if (newDateRange && newDateRange.length === 2) {
    startDate.value = newDateRange[0];
    endDate.value = newDateRange[1];
    fetchStakeSummary();
  }
});

watch(groupBy, () => {
  fetchStakeSummary();
});
</script>

<template>
  <div class="container py-4">
    <div class="card mt-4">
      <div class="card-header pb-0 p-3 d-flex justify-content-between align-items-center">
        <h6 class="mb-0">Excise Statistics</h6>

        <div class="d-flex gap-2">
          <!-- Date Picker -->
          <div class="" >
            <VueDatePicker v-model="pickerDate" range multi-calendars :enable-time-picker="false" :clearable="false" class="datePicker" />
          </div>

          <!-- Group By Dropdown -->
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">
              <i class="fas fa-filter"></i> Group By
            </button>
            <ul class="dropdown-menu">
              <li @click="groupBy = 'daily'"><a class="dropdown-item" href="#">Daily</a></li>
              <li @click="groupBy = 'monthly'"><a class="dropdown-item" href="#">Monthly</a></li>
            </ul>
          </div>

        </div>
      </div>

      <div class="card-body p-3">
        <table class="table">
          <thead>
          <tr>
            <th>Period</th>
            <th>Total Stake</th>
            <th>Tax on Stake</th>
            <th>Status Description</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in stakeSummary" :key="index">
            <td>{{ formatPeriod(item.period) }}</td>
            <td>{{ item.total_stake }}</td>
            <td>{{ item.tax_on_stake }}</td>
            <td>{{ item.status_description }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>


<style>
.datePicker{
  background-color: #8392ab;
}
</style>

