<script setup>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref, watch, onMounted } from "vue";
import axios from "axios";
import { useStore } from "vuex";

const store = useStore();

const formatDate = (date) => {
  return date.toISOString().split("T")[0]; // Extract YYYY-MM-DD
};

const today = new Date();
const startDate = ref(today);
const endDate = ref(today);
const pickerDate = ref([startDate.value, endDate.value]);
const outcomeSummary = ref([]);
const groupBy = ref("daily");
const statusFilter = ref(null);

const formatPeriod = (timestamp) => {
  return timestamp.split('T')[0];
};

const fetchOutcomeSummary = async () => {
  const baseurl = process.env.VUE_APP_API_TAX_URL;
  const user = store.state.user;

  const start = formatDate(startDate.value);
  const end = formatDate(endDate.value);
  const group = groupBy.value;
  const status = statusFilter.value;

  let url = `${baseurl}/stake/outcome?start=${start}&end=${end}&group_by=${group}`;

  if (status !== null) {
    url += `&status=${status}`;
  }

  try {
    const response = await axios.post(
        url,
        {},
        {
          headers: {
            "api-key": user.auth,
          },
        }
    );

    console.log("API Response:", response.data);

    if (response.data && response.data.outcome_summary) {
      outcomeSummary.value = response.data.outcome_summary;
      console.log("Outcome Summary Set:", outcomeSummary.value);
    } else {
      outcomeSummary.value = [];
      console.warn("No outcome_summary in response");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

onMounted(fetchOutcomeSummary);

watch(pickerDate, (newDateRange) => {
  if (newDateRange && newDateRange.length === 2) {
    startDate.value = newDateRange[0];
    endDate.value = newDateRange[1];
    fetchOutcomeSummary();
  }
});

watch(groupBy, () => {
  fetchOutcomeSummary();
});

watch(statusFilter, () => {
  fetchOutcomeSummary();
});
</script>
<template>
  <div class="container py-4">
    <div class="card mt-4">
      <div class="card-header pb-0 p-3 d-flex justify-content-between align-items-center">
        <h6 class="mb-0">Withholding Statistics</h6>

        <div class="d-flex gap-2">
          <!-- Date Picker -->
            <div class="" >
              <VueDatePicker v-model="pickerDate" range multi-calendars :enable-time-picker="false" :clearable="false" class="datePicker" />
            </div>

          <!-- Group By Dropdown -->
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">
              <i class="fas fa-filter"></i> Group By
            </button>
            <ul class="dropdown-menu">
              <li @click="groupBy = 'daily'"><a class="dropdown-item" href="#">Daily</a></li>
              <li @click="groupBy = 'monthly'"><a class="dropdown-item" href="#">Monthly</a></li>
            </ul>
          </div>

          <!-- Status Filter Dropdown -->
          <div class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">
              <i class="fas fa-filter"></i> Status
            </button>
            <ul class="dropdown-menu">
              <li @click="statusFilter = 0"><a class="dropdown-item" href="#">Lost</a></li>
              <li @click="statusFilter = 1"><a class="dropdown-item" href="#">Won</a></li>
              <li @click="statusFilter = null"><a class="dropdown-item" href="#">All</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="card-body p-3">
        <table class="table">
          <thead>
          <tr>
            <th>Period</th>
            <th>Total Winning</th>
            <th>Tax on Winning</th>
            <th>Status Description</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in outcomeSummary" :key="index">
            <td>{{ formatPeriod(item.period) }}</td>
            <td>{{ item.total_winning }}</td>
            <td>{{ item.tax_on_winning }}</td>
            <td>{{ item.status_description }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style>
.datePicker{
  background-color: #8392ab;
}
</style>








